import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useState } from "react";
import { whatWeDoContent } from "./WhatWeDoContent";
import "./Style.css";
import "./whatWeDoStyles.css";
export default function WhatWeDo() {
  const [selectedSection, setSelectedSection] = useState(
    "Software Development"
  );

  const buttonStyles = {
    border: "none",
    outline: "none",
    background: "none",
    fontSize: "13px",
    whiteSpace: "normal",
    transition: "all .5s",
    width: "100px",
  };

  const buttonValues = [
    "Software Development",
    "Process Improvement",
    "Sales & Marketing",
    "Project Management",
    "Vendor Management",
    "Financial Modeling",
  ];
  return (
    <div
      className="main"
      // className="pt-5"
    >
      <div style={{ textAlign: "center", paddingBottom: "2%" }}>
        <h1 className="header">What we love to do</h1>
        <p className="subHeader">
          We empower small to medium sized business with tailored solutions
        </p>
      </div>
      <div
        style={{
          justifyContent: "center",
          paddingTop: "15px",
          paddingBottom: "15px",
          borderRadius: "10px",
          display: "flex",
          gap: "2rem",
          border: "1px solid #E7EBEC",
          backgroundColor: "#FCFCFF",
          flexWrap: "wrap",
        }}
        className="d-none d-sm-flex"
      >
        {buttonValues.map((value) => (
          <button
            style={{
              fontWeight: selectedSection === value ? "800" : "500",
              color: selectedSection === value ? "black" : "#979797",
              borderBottom: "none",
              ...buttonStyles,
              flex: "1",
              minWidth: "100px",
            }}
            key={value}
            onClick={() => setSelectedSection(value)}
          >
            {value}
          </button>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          gap: "1rem",
          padding: "3px",
          overflowX: "auto",
          width: "100%",
        }}
        className="d-flex d-sm-none"
      >
        {buttonValues.map((value) => {
          return (
            <button
              onClick={() => setSelectedSection(value)}
              style={{
                borderRadius: "50px",
                fontSize: "12px",
                backgroundColor:
                  value === selectedSection ? "#161D27" : "#E7EBEC",
                border: "none",
                color: value === selectedSection ? "white" : "#161D27",
                padding: "10px 15px",
                whiteSpace: "nowrap",
                width: "fit-content",
                transition: "all .5s",
              }}
            >
              {value}
            </button>
          );
        })}
      </div>

      <Container
        style={{ paddingTop: "2%", paddingRight: "5%", paddingLeft: "5%" }}
      >
        {/* {whatWeDoContent.map((content) => {
          return (
            <Row
              className="justify-content-center align-items-center"
              style={{
                display: content.id === selectedSection ? "flex" : "none",
                transition: "all .2s",
              }}
            >
              <Col lg={6} className="order-1 order-lg-0">
                <h2 style={{ fontFamily: "font-semibold", lineHeight: 1.7 }}>
                  {content.header}
                </h2>
                <p style={{ lineHeight: 1.8 }}>{content.text}</p>
              </Col>

              <Col
                lg={6}
                className="order-0 order-lg-1"
                style={{ textAlign: "center" }}
              >
                <Image className="w-100" src={content.img} />
              </Col>
            </Row>
          );
        })} */}

        {whatWeDoContent.filter((item) => item.id === selectedSection)
        .map((item) => (
          <>
           <Row
              className="justify-content-center align-items-center"
              style={{
                
                transition: "all .2s",
              }}
            >
              <Col lg={6} className="order-1 order-lg-0">
                <h2 style={{ fontFamily: "font-semibold", lineHeight: 1.7 }}>
                  {item.header}
                </h2>
                <p style={{ lineHeight: 1.8 }}>{item.text}</p>
              </Col>

              <Col
                lg={6}
                className="order-0 order-lg-1"
                style={{ textAlign: "center" }}
              >
                <Image className="w-100" src={item.img} />
              </Col>
            </Row>

          </>
        ))
        }
      </Container>
    </div>
  );
}
