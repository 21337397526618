import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Style.css";

function NavbarComponent() {
  return (
    <Navbar
      expand="lg"
      style={{
        backgroundColor: "#161D27",
        paddingTop: "30px",
        paddingBottom: "30px",
      }}
    >
      <Container>
        <Navbar.Brand
          style={{
            fontFamily: "Inter, sans-serif",
            fontWeight: "bold",
            fontSize: 32,
          }}
          className="text-white"
          href="#"
        >
          BVG
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" className="bg-white" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mx-auto my-2 my-lg-0 gap-4 justify-content-center"
            style={{
              maxHeight: "fit-content",
              fontFamily: "Inter, sans-serif",
            }}
            navbarScroll
          >
            <Nav.Link className="text-white" href="#services">
              Services
            </Nav.Link>
            <Nav.Link className="text-white" href="#process">
              Our Process
            </Nav.Link>
            <Nav.Link onClick={() =>
              window.open(
                "https://sgdh3u2xs16.typeform.com/to/nzh7TbyN",
                "_self"
              )
            } className="text-white">
              Contact
            </Nav.Link>
            <Nav.Link className="text-white" href="#action4">
              Blog (Coming soon)
            </Nav.Link>
          </Nav>
          <Button
            onClick={() =>
              window.open(
                "https://sgdh3u2xs16.typeform.com/to/nzh7TbyN",
                "_self"
              )
            }
            style={{
              fontWeight: 600,
              fontFamily: "Inter, sans-serif",
              paddingRight: "4%",
              paddingLeft: "4%",
              paddingTop: "1.2%",
              paddingBottom: "1.2%",
            }}
            className="bg-white text-black border-0"
          >
            Let's Talk
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
