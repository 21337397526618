import React from "react";

//!Components
import Hero from "./components/Hero";
import WhatWeDo from "./components/WhatWeDo";
import OurProcess from "./components/OurProcess";
import Benefits from "./components/Benefits";

import { Stack } from "react-bootstrap";

export default function Homepage() {
  return (
    <div>
      {/* <Hero />
      <WhatWeDo/>
      <OurProcess/> */}

      <Stack gap={5}>
        <div>
          <Hero />
        </div>
        <div id='services' className="p-2 mt-3">
          <WhatWeDo />
        </div>
        <div id="process" className="p-2 mt-5">
          <OurProcess />
        </div>
        <div className="p-2 mt-5">
          <Benefits />
        </div>
      </Stack>
    </div>
  );
}
