import pm from "../../../images/whatWeDoImages/rsz_1pm.png";
import softwaredev from "../../../images/whatWeDoImages/rsz_1softwaredev.png";
import vendormng from "../../../images/whatWeDoImages/rsz_1vendor.png";
import process from "../../../images/whatWeDoImages/rsz_1process.png";
import financialmodeling from "../../../images/whatWeDoImages/rsz_1financialmodeling.png";
import salesnm from "../../../images/whatWeDoImages/rsz_1salesmarketing.png";

import image from '../../../images/testimage.png'

export const whatWeDoContent = [
  {
    id: "Sales & Marketing",
    header: "Sales & Marketing",
    text: "Are you struggling to generate leads and grow your business? We can help you develop and execute marketing strategies that will reach your target audience and generate the results you need.",
    img: salesnm,
  },
  {
    id: "Software Development",
    header: "Software Development",
    text: "Do you need a custom software solution that meets your specific business needs? We can help you design, develop, and deploy a solution that will help you streamline your operations, improve efficiency, and boost productivity.",
    img: softwaredev,
  },
  {
    id: "Project Management",
    header: "Project Management",
    text: "Are you looking to plan, manage, and deliver projects on time and within budget? We can help you break down your project into manageable tasks, track progress, and identify and resolve any issues that arise.",
    img: pm,
  },
  {
    id: "Vendor Management",
    header: "Vendor Management",
    text: "Are you looking to select and manage vendors to get the best possible prices and services? We can help you create a vendor RFP, evaluate proposals, and negotiate contracts.",
    img: vendormng,
  },
  {
    id: "Financial Modeling",
    header: "Financial Modeling.",
    text: "Do you need help creating financial models that will help you make better business decisions? We can help you build models that forecast revenue, expenses, and cash flow.",
    img: financialmodeling,
  },
  {
    id: "Process Improvement",
    header: "Process Improvement",
    text: "Are you looking to identify and improve inefficient processes in your organization? We can help you map out your processes, identify areas for improvement, and implement changes that will save you time and money.",
    img: process,
  },
];
