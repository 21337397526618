import "./App.css";

//!Test

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'


//!Components
import NavbarComponent from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";


//!Pages 
import Homepage from "./pages/Homepage/Homepage";





function App() {
  return (
    <div>
      <Router>
        <NavbarComponent/>
        <Routes>
          <Route path="/" element={<Homepage/>}/>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
