import React from "react";
import { Button } from "react-bootstrap";
import "./Style.css";
import hero from "../../../images/rsz_hero.jpg";
import "./heroStyles.css";
export default function Hero() {
  return (
    <header style={{ paddingLeft: 0 }}>
      <div
        className="text-center bg-image"
        style={{
          backgroundImage: `url(${hero})`,
          height: 800,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          className="mask"
          style={{ backgroundColor: "rgba(22, 29, 39, 0.7)", height: "100%" }}
        >
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-white">
              <h1 className="mb-3 fade-in-text headerHero">
                Let's partner together to win
              </h1>
              <p
                className="mb-3 fade-in-text w-75 mx-auto"
                style={{ fontSize: 22 }}
              >
                BVG provides a wide range of management and technical consulting
                services to small and medium-sized businesses.
              </p>
              <Button
                onClick={() =>
                  window.open(
                    "https://sgdh3u2xs16.typeform.com/to/nzh7TbyN",
                    "_self"
                  )
                }
                className="bg-white text-black border-0 fade-in-text buttonHero"
              >
                Let's Talk
              </Button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
