import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Footer = () => {
  return (
    <div>
      <footer style={{ backgroundColor: "#161D27" }}>
        <Container style={{ paddingTop: "4%", paddingBottom: "4%" }}>
          <Row className="justify-content-center align-items-center">
            <Col>
              <h2
                style={{ fontFamily: "font", color: "white", lineHeight: 1.5 }}
              >
                Interested in how BVG can help your business?
              </h2>
              <p style={{ color: "white", lineHeight: 1.5 }}>
                Drop us a line. We’d love to learn more about your business.
              </p>
            </Col>

            <Col className="justify-content-center d-flex">
              <Button
                onClick={() =>
                  window.open(
                    "https://sgdh3u2xs16.typeform.com/to/nzh7TbyN",
                    "_self"
                  )
                }
                className="bg-white text-black border-0 fade-in-text"
                style={{
                  fontWeight: 600,
                  fontFamily: "Inter, sans-serif",
                  paddingRight: "7%",
                  paddingLeft: "7%",
                  paddingTop: "2%",
                  paddingBottom: "2%",
                }}
              >
                Let's Talk
              </Button>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;


