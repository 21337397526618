import React from "react";
import { Stack, Container, Row, Col, Image } from "react-bootstrap";
import "./Style.css";
import { useState } from "react";
import { ourProcess } from "./OurProcessContent";
import Carousel from "react-bootstrap/Carousel";
import "./ourProcessStyles.css";

const OurProcess = () => {
  const [selectedSection, setSelectedSection] = useState("Evaluation");
  const buttonStyles = {
    border: "none",
    outline: "none",
    background: "none",
    fontSize: "16px",
    transition: "all .5s",
  };
  const buttonValues = [
    "Evaluation",
    "Solutioning",
    "Design",
    "Execution",
    "Measure Success",
  ];
  return (
    <Stack gap={3} style={{ padding: "10px" }}>
      <div className="p-2">
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              borderRadius: "50px",
              fontSize: "10px",
              backgroundColor: "#161D27",
              border: "none",
              color: "white",
              padding: "10px 30px",
              whiteSpace: "nowrap",
              width: "fit-content",
              transition: "all .5s",
              fontWeight: 700,
            }}
          >
            OUR PROCESS
          </button>
        </div>
        <h1 className="header">How we help you win</h1>
        <p className="subHeader">
          BVG brings strategies of the modern world in corporate America to the
          small and medium sized businesses in the heartland of our country.
        </p>
      </div>
      <Container
        className="justify-content-center align-items-center"
        style={{ padding: "15px 7%" }}
      >
        <Row className="justify-content-center align-items-center">
          <Col className="d-none d-md-block">
            <div
              style={{
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: ".7rem",
                padding: "15px 100px",
                marginLeft: "5%",
              }}
            >
              {buttonValues.map((value) => {
                return (
                  <button
                    style={{
                      fontFamily: "font-semibold",
                      textAlign: "left",
                      color: selectedSection === value ? "black" : "#ABA9A9",
                      ...buttonStyles,
                    }}
                    key={value}
                    onClick={() => setSelectedSection(value)}
                  >
                    {value}
                  </button>
                );
              })}
            </div>
          </Col>
          {ourProcess
            .filter((item) => item.id === selectedSection)
            .map((item) => (
              <>
                <Col className="d-flex justify-content-center align-items-center d-none d-md-block">
                  <Image className="w-100" src={item.image} />
                </Col>
                <Col className="d-none d-md-block">
                  <h2 style={{ fontFamily: "font-semibold", lineHeight: 1.5 }}>
                    {item.header}
                  </h2>
                  <p
                    style={{ fontFamily: "Inter, sans-serif", lineHeight: 1.8 }}
                  >
                    {item.text}
                  </p>
                </Col>
              </>
            ))}

          <Container className="d-flex justify-content-center align-items-center">
            <Carousel
              slide={false}
              className="d-md-none custom-carousel"
              data-bs-theme="dark"
            >
              {ourProcess.map((processStep) => (
                <Carousel.Item key={processStep.id}>
                  <div className="carousel-title">{processStep.title} </div>
                  <img
                    className="d-block w-100"
                    src={processStep.image}
                    alt={processStep.header}
                  />
                  <div className="carousel-content">
                    <h3 className="header3">{processStep.header}</h3>
                    <p className="caption">{processStep.text}</p>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </Container>
        </Row>
      </Container>
    </Stack>
  );
};

export default OurProcess;
