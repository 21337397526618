import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { benefitContent } from "./BenefitsContent";

const Benefits = () => {
  return (
    <Container className="pb-5">
      <h3
        style={{
          fontFamily: "font-semibold",
          textAlign: "center",
          lineHeight: 1.5,
          width: "75%",
          margin: "auto",
        }}
      >
        BVG believes that small and medium-sized businesses with the ambition to
        grow and achieve meaningful success are a reservoir of untapped talent.
      </h3>

      <Container style={{ marginTop: "6rem" }}>
        <Row className="d-flex justify-content-center gap-5">
          {benefitContent.map((content) => (
            <Col sm={6} md={4} lg={3}>
              {/* 
          On small screens (sm and below), each column will take 12 columns (stacked).
          On medium screens (md), each column will take 6 columns.
          On large screens (lg), each column will take 4 columns.
        */}
              <p style={{ fontFamily: "Inter, sans-serif", fontWeight: "900" }}>
                {content.header}
              </p>
              <p style={{ fontFamily: "Inter, sans-serif" }}>{content.text}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default Benefits;
