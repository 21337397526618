import evaluation from "../../../images/ourProcess/rsz_evaluation-new-3.png";
import solutioning from "../../../images/ourProcess/rsz_1solutioning-new-3.png";
import execution from "../../../images/ourProcess/rsz_1execution-new-3.png";
import measureSuccess from "../../../images/ourProcess/rsz_1measure-success-new-3.png";
import design from "../../../images/ourProcess/rsz_1design-new-3.png";

import image from '../../../images/testimage.png'

export const ourProcess = [
  {
    id: "Evaluation",
    image: evaluation,
    header: "Assess your needs.",
    text: "We will assess your current situation to understand your needs and goals.",
  },

  {
    id: "Solutioning",
    image: solutioning,
    header: "Generate a solution.",
    text: "We will develop a solution that is tailored to your needs and helps you achieve your goals.",
  },

  {
    id: "Design",
    image: design,
    header: "Create a plan.",
    text: "We will establish a course of action that is feasible, efficient, and effective to implement your solution.",
  },

  {
    id: "Execution",
    image: execution,
    header: "Make it work.",
    text: "We will execute your plan and ensure that it is implemented successfully.",
  },

  {
    id: "Measure Success",
    image: measureSuccess,
    header: "Track your progress.",
    text: "We will monitor your performance and measure your success to ensure that you are on track to achieve your goals.",
  },
];
